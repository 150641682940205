/**
 *       **** Readme ****
 * 
 * Component: <Table />
 * Purpose: Contains the print styles for the reports in react-table
 * 
 * Creation date: 16/December/2021
 * Last update: 17/December/2021
 */

@media print {
  .print-source {
    display: flex;
  }

  @page {
    size: auto;
    margin: 4%;
  }
  table {
    border-spacing: 0;
    border-radius: 10px !important;
    border-collapse: collapse;
    width: 100%;
  }

  tr:last-child td {
    border-bottom: 0;
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    font-family: var(--font-family-mulish);
  }

  th.row-select:first-child,
  td.row-select:first-child,
  th.first-col + th.row-select,
  td.first-col + td.row-select,
  .arrow-sorted {
    display: none !important;
  }

  th:last-child,
  td:last-child {
    border-right: 0;
  }

  thead tr {
    background-color: #f7f7f7;
  }

  thead th {
    padding-left: 0;
    padding-right: 0;
    padding-top: 15px;
    font-weight: 900;
    font-size: 14px;
    text-align: center;
    line-height: 19px;
    color: #4d4d4d;
  }

  thead th:first-child {
    padding-left: 30px;
  }

  thead th:last-child {
    padding-right: 30px;
  }

  thead th p.title-header {
    text-align: left;
    padding-left: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  thead th input::placeholder {
    color: #d6dadb;
    font-size: 14px;
  }

  thead th input:focus-visible {
    outline: none;
  }

  thead th:first-child input {
    padding: 5px 7px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left-color: #dddddd;
  }

  thead th div.element-filter {
    height: 29px !important;
  }

  thead th div.element-summation {
    height: 27px;
    padding-left: 5px;
    padding-right: 5px;
    line-height: 30px;
    font-size: 16px;
    color: #575757;
    width: max-content;
    font-weight: 700;
  }

  tbody tr {
    color: #575757;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
  }

  tbody tr td {
    width: 75px;
    min-width: 58px;
  }

  tbody tr:nth-child(odd) {
    background-color: #fff0f3;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  tbody tr:nth-child(even) {
    background-color: #fff;
  }

  tbody tr:last-child {
    border-bottom: 1px solid transparent;
  }

  div.element-summation {
    width: 100%;
    text-align: left;
  }

  div.element-filter,
  span.arrow-sorted,
  .settings-table,
  .first-col,
  .no-print {
    display: none;
  }

  .img {
    display: inline-block;
  }
}
